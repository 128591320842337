import React, { useState, useEffect } from "react";
import logo from "./images/usc_posh.png";
import sar_adc_mlg from "./images/sar_adc_mlg.png";
import Chart from "react-google-charts";
import axios from "axios";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import { map, isEmpty, values } from "lodash";
import "./css/App.css";
import { Button, Form, Table, Alert, Image } from "react-bootstrap";

Modal.setAppElement("#root");

const PLOT_RADIUS = 6;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
  },
};

const validatePost = ({
  nbit,
  sampling_frequency,
  nbit_lower_bound,
  fs_lower_bound,
  power_upper_bound,
}) => {
  let post = {};

  if (!isEmpty(nbit)) {
    post.nbit = parseInt(nbit);
  }
  if (!isEmpty(sampling_frequency)) {
    post.sampling_frequency = parseInt(sampling_frequency);
  }
  if (!isEmpty(nbit_lower_bound)) {
    post.nbit_lower_bound = parseInt(nbit_lower_bound);
  }
  if (!isEmpty(fs_lower_bound)) {
    post.fs_lower_bound = parseInt(fs_lower_bound);
  }
  if (!isEmpty(power_upper_bound)) {
    post.power_upper_bound = parseInt(power_upper_bound);
  }

  return post;
};

/** Interactive Graph */
const bitInterpolate = (y) => {
  const nbit = (9 / 450) * (450 - y) + 1;
  if (nbit > 9) {
    return 10;
  }
  return Math.round(nbit);
};

const samplingInterpolate = (x) => {
  return (1100 / 880) * x;
};

const generateGrid = () => {
  let res = [];
  for (let i = 0; i < 99; i++) {
    res.push(<div></div>);
  }

  return res;
};

const generateGridYAxis = () => {
  let res = [
    <div style={{ position: "absolute", marginTop: -13, marginLeft: -35 }}>
      10
    </div>,
  ];
  for (let i = 1; i < 10; i++) {
    res.push(
      <div
        style={{
          position: "absolute",
          marginTop: i * 50 - 13,
          marginLeft: -30,
        }}
      >
        {10 - i}
      </div>
    );
  }
  return res;
};

const generateGridXAxis = () => {
  let res = [<div style={{ position: "absolute" }}>0</div>];
  for (let i = 1; i < 12; i++) {
    res.push(
      <div style={{ position: "absolute", marginLeft: 80 * i - 10 }}>
        {i * 100}
      </div>
    );
  }
  return res;
};

const graph_data = [
  { nbit: 9, sampling_frequency: 156000000, x: 124.8, y: 50 },
  { nbit: 8, sampling_frequency: 187500000, x: 150, y: 100 },
  { nbit: 7, sampling_frequency: 250000000, x: 200, y: 150 },
  { nbit: 6, sampling_frequency: 343750000, x: 275, y: 200 },
  { nbit: 5, sampling_frequency: 438000000, x: 350.4, y: 250 },
  { nbit: 4, sampling_frequency: 562500000, x: 450, y: 300 },
  { nbit: 3, sampling_frequency: 718750000, x: 575, y: 350 },
  { nbit: 2, sampling_frequency: 968750000, x: 775, y: 400 },
];

/** Table */
const MultiColTable = ({ data, type = "v" }) => {
  if (type) {
    return (
      <Table hover bordered>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {map(data, (value, key) => {
            return (
              <tr>
                <th scope="row">{key}</th>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
  return (
    <Table hover bordered>
      <thead>
        <tr>
          <th></th>
          {map(data, (_, key) => {
            return <th>{key}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Field</th>
          {map(data, (value) => {
            return <td>{value}</td>;
          })}
        </tr>
      </tbody>
    </Table>
  );
};

const App = () => {
  const [isLoading, setLoading] = useState(false);
  const [validRegion, setValidRegion] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState({});

  const [chartData, setChartData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const [inputValues, setInputValues] = useState({
    nbit: "5",
    sampling_frequency: "500000000",
    fs_lower_bound: "",
    power_upper_bound: "",
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const closeModal = () => setIsOpen(false);

  const onSearch = () => setLoading(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isLoading) {
      axios
        .post("http://10.136.200.79:5000/algorithm", validatePost(inputValues))
        .then((res) => {
          let newChartData = [];

          if (!isEmpty(res.data)) {
            const scatterData = map(res.data, (_, key) => {
              const matches = key.match(/\d+/g);
              let sampling = parseInt(matches[1]) / 1000000;
              const bit = parseInt(matches[0]);
              return [
                sampling,
                bit,
                `nbits: ${bit} \n Sampling rate: ${sampling}`,
              ];
            });

            newChartData = [
              ["Sampling rate", "nbits", { role: "tooltip", type: "string" }],
              ...scatterData,
            ];
          }

          setChartData(newChartData);
          setSearchData(values(res.data));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [isLoading]);

  return (
    <div className="wrapper bg-color mb-5 py-2">
      <h1 className="text-center font-weight-bold text-danger pt-5 pb-4">
        Analog Mixed Signal Parameter Search Engine
      </h1>
      <hr />
      <div className="mb-4">
        <img src={logo} className="ml-2" alt="logo" />
        <div className="authorBlock ml-5">
          <h2>USC POSH GROUP</h2>
          <h4>Tony Levi</h4>
          <h4>Mike Chen</h4>
          <h4>Sandep Gupta</h4>
        </div>
      </div>
      <hr />
      <h2 className="mb-2 ml-2">SAR</h2>
      <h5 className="text-center mt-2">KGD and Circuit Graph</h5>
      <div className="text-center">
        <Image width={880} height={380} src={sar_adc_mlg} />
      </div>

      <h5 className="text-center mt-5">Suggested Region</h5>
      <div className="gridContainer">
        <>{generateGridYAxis()}</>
        <div className="font-weight-bold nbitsTitle">Number of Bits</div>
        <div className="grid">{generateGrid()}</div>

        <div
          className="interactive"
          onClick={(e) => {
            const nbit = bitInterpolate(e.nativeEvent.offsetY);
            const sampling_frequency =
              samplingInterpolate(e.nativeEvent.offsetX) * 1000000;
            if (
              nbit > 1 &&
              nbit < 10 &&
              graph_data[9 - nbit].sampling_frequency >= sampling_frequency
            ) {
              const selected = {
                nbit: nbit.toString(),
                sampling_frequency: sampling_frequency.toString(),
              };
              setValidRegion(true);
              setSelectedRegion(selected);
              setInputValues({
                ...inputValues,
                ...selected,
              });
            } else {
              setValidRegion(false);
            }
          }}
        ></div>
        <>
          {map(graph_data, (coord) => (
            <div
              className="graphData"
              style={{
                marginTop: coord.y - PLOT_RADIUS - 450,
                marginLeft: coord.x - PLOT_RADIUS,
              }}
              onClick={() => {
                const selected = {
                  nbit: coord.nbit.toString(),
                  sampling_frequency: coord.sampling_frequency.toString(),
                };
                setValidRegion(true);
                setSelectedRegion(selected);
                setInputValues({
                  ...inputValues,
                  ...selected,
                });
              }}
            ></div>
          ))}
        </>

        <div style={{ marginTop: 15 }}>{generateGridXAxis()}</div>
        <div className="text-center font-weight-bold" style={{ marginTop: 60 }}>
          Sampling Rate (MS/s)
        </div>
      </div>
      {validRegion && !isEmpty(selectedRegion) && (
        <Alert variant="success">
          You just selected{" "}
          <b>
            ({selectedRegion.nbit},{selectedRegion.sampling_frequency})
          </b>
        </Alert>
      )}

      {!validRegion && (
        <Alert variant="danger">Invalid region, please select again</Alert>
      )}

      <hr />
      <Form className="px-2">
        <Form.Group className="mb-4" controlId="bitNumber">
          <Form.Label className="font-weight-bold">Number of bits</Form.Label>
          <Form.Control
            type="number"
            placeholder="Number of bits"
            name="nbit"
            value={inputValues.nbit}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="samplingFrequency">
          <Form.Label className="font-weight-bold">
            Sampling Frequency
          </Form.Label>
          <Form.Control
            type="number"
            placeholder=""
            name="sampling_frequency"
            value={inputValues.sampling_frequency}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="lowerBound">
          <Form.Label className="font-weight-bold">
            Lower Bound for Number of Bits
          </Form.Label>
          <Form.Control
            type="number"
            name="nbit_lower_bound"
            value={inputValues.nbit_lower_bound}
            onChange={handleInputChange}
            placeholder=""
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="samplingFrequencyLowerBound">
          <Form.Label className="font-weight-bold">
            Lower Bound for Sampling Frequency
          </Form.Label>
          <Form.Control
            type="number"
            name="fs_lower_bound"
            value={inputValues.fs_lower_bound}
            onChange={handleInputChange}
            placeholder=""
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="upperBound">
          <Form.Label className="font-weight-bold">
            Upper Bound for Power
          </Form.Label>
          <Form.Control
            type="number"
            name="power_upper_bound"
            value={inputValues.power_upper_bound}
            onChange={handleInputChange}
            placeholder=""
          />
        </Form.Group>
      </Form>
      <Button
        className="ml-2"
        variant="primary"
        disabled={isLoading}
        onClick={!isLoading ? onSearch : null}
      >
        {isLoading ? "Loading…" : "Search"}
      </Button>
      <hr />
      <h2 className="mb-2 ml-2">Result</h2>
      {isLoading ? (
        <Loader
          className="text-center my-5"
          type="Audio"
          color="#00BFFF"
          height={100}
          width={100}
        />
      ) : isEmpty(chartData) ? (
        <h5 className="text-center my-5">No Data</h5>
      ) : (
        <Chart
          height={"800px"}
          chartType="ScatterChart"
          loader={<h5 className="text-center my-5">Loading Chart ...</h5>}
          data={chartData}
          options={{
            hAxis: {
              title: "Sampling Rate (MS/s)",
              minValue: 0,
              format: "short",
              titleTextStyle: {
                fontSize: 24,
              },
            },
            vAxis: {
              title: "Number of bits",
              minValue: 0,
              format: "#",
              titleTextStyle: {
                fontSize: 24,
              },
            },
            legend: "none",
            tooltip: { trigger: "visible" },
          }}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 1) {
                  setSelectedData(searchData[selection[0].row]);
                  setIsOpen(true);
                }
              },
            },
          ]}
          rootProps={{ "data-testid": "1" }}
        />
      )}
      <hr />

      {!isEmpty(selectedData) && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div>
            <div className="clearfix">
              <Button
                className="btn-danger float-right my-4"
                onClick={closeModal}
              >
                Close
              </Button>
            </div>
            <h2 className="mb-4 text-primary">Parameters</h2>
            <hr />

            <h5 className="text-danger mt-4 mb-3">Sequential_1</h5>
            <MultiColTable data={selectedData.params.Sequential_1} />

            <h5 className="text-danger mt-5 mb-3">Sequential_2</h5>
            <MultiColTable data={selectedData.params.Sequential_2} />

            <h5 className="text-danger mt-5 mb-3">Comparator</h5>
            <MultiColTable data={selectedData.params.Comparator} />

            <h5 className="text-danger mt-5 mb-3">SAR_DAC</h5>
            <MultiColTable data={selectedData.params.SAR_DAC} />

            <h5 className="text-danger mt-5 mb-3">Others</h5>
            <MultiColTable
              data={{
                d_tr: selectedData.params.d_tr,
                n_dly: selectedData.params.n_dly,
              }}
            />

            <h2 className="mt-5 text-primary">Metrics</h2>
            <hr />

            <MultiColTable data={selectedData.specs} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default App;
